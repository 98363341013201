import Screens2x from "./screens@2x.png";
import Screens from "./screens.png";
import Style from "./home.module.scss";
import CheckIcon from "./icon_checkmark.svg";
import CrashClaimLogo from "./logo_crash_claim.png";
import PdLogo from "./logo_pd_com_au.png";
import RatencyFooter from "../../components/ratencyFooter/ratencyFooter";

export function Home({ user }) {
  const year = new Date().getFullYear();

  return (
    <div className={Style.page_container}>
      <div className={Style.hero_1_container}>
        <div className="container">
          <div className={`row`}>
            <div className="col-12 col-sm-6 no-padding">
              <div className={Style.hero_1}>
                <div className={Style.left_side}>
                  <p className={Style.title}>
                    <strong>Historical car hire rates</strong>
                  </p>
                  <p className={Style.footer}>
                    Access our database of historical pricing and vehicle
                    availability, sourced directly from car rental companies
                    right across the country
                  </p>
                  {user ? (
                    <div className={Style.cta_group}>
                      <a className={Style.cta_primary} href="/app/">
                        Search on Ratency
                      </a>
                      <a className={Style.cta_secondary} href="/history">
                        My search history
                      </a>
                    </div>
                  ) : (
                    <div className={Style.cta_group}>
                      <a className={Style.cta_primary} href="/signin">
                        Log in
                      </a>
                      <a className={Style.cta_secondary} href="/contact">
                        Contact us
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`col-12 col-sm-6 no-padding ${Style.home_screens_image}`}
            >
              <span>
                <img
                  src={Screens}
                  alt="Ratency"
                  srcSet={`${Screens}, ${Screens2x} 2x`}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={Style.testimonials_wrapper}>
        <div className="container">
          <h2>Testimonials</h2>
          <div className="row">
            <div className="col-12 col-sm-6 mb-12">
              <div className={Style.testimonial_card}>
                <div>
                  <span className="block">
                    <img src={CrashClaimLogo} alt="CrashClaim" />
                  </span>
                  <h3>
                    Transformed our workflow and enhanced negotiations regarding
                    car hire costs with insurers.
                  </h3>
                  <p>
                    Since 2023, our recoveries team has been utilising Vroom's
                    historical car hire rates and availability data as a vital
                    tool. This partnership has transformed our workflow and
                    enhanced negotiations regarding car hire costs with
                    insurers. Before adopting Vroom, negotiating rental costs
                    with insurers lacked credibility when we did not have a
                    third-party source of information to justify rental rates
                    for the types of vehicles our non-fault customers required
                    at the time of their car being repaired.
                  </p>
                  <p>
                    Now, with Vroom's comprehensive data, we negotiate rental
                    settlements armed with accurate, reliable insights into
                    market rates for the specific car types needed, reflecting
                    both the time and location of their supply.
                  </p>
                  <p>
                    Vroom's reports have streamlined our discussions with
                    insurers on car hire market rates and led to fairer
                    settlements. Having tested numerous credit hire
                    applications, Vroom stands out as one of the best in the
                    industry. Its credit hire data, combined with expert
                    support, delivers unparalleled value. Vroom is also
                    user-friendly making it an invaluable asset for our team.
                  </p>
                  <p>7 January 2025</p>
                  <span className={Style.testimonial_name}>
                    <strong className="block">Angela</strong>
                    <small>Recoveries Manager at Crashclaim Recoveries</small>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 mb-12">
              <div className={Style.testimonial_card}>
                <div>
                  <span className="block">
                    <img src={PdLogo} alt="pd.com.au" />
                  </span>
                  <h3>
                    Vroom's credit hire data and expert support is second to
                    none.
                  </h3>
                  <p>
                    We've been using Vroom's historical car hire rates and
                    availability data as a tool in our claim settlements team
                    since 2022. It has transformed our workflow, saving us time
                    and effectively reducing our third party settlements.
                  </p>
                  <p>
                    Before we started to work with Vroom, defending credit hire
                    claims for unreasonable daily rates was labour intensive and
                    lacking in credible defensive information.
                  </p>
                  <p>
                    Now, when we receive a credit hire claim, we have an
                    accurate and reliable picture of the market rate for the
                    type of car that was required at the time and in the
                    location that it was supplied. Credit hire claimants
                    acknowledge Vroom's reports as a trusted source of data,
                    streamlining our negotiations on market rate for car hire
                    and resulting in reasonable settlements.
                  </p>
                  <p>
                    Vroom's credit hire data and expert support is second to
                    none. In addition to improved claims settlement results, it
                    has saved my team many hours each week, creating
                    efficiencies, allowing us to increase capacity without
                    additional headcount and improving commercial outcomes
                  </p>
                  <p>28 October 2024</p>
                  <span className={Style.testimonial_name}>
                    <strong className="block">Jill White</strong>
                    <small>
                      Recoveries and Settlements Manager at PD Insurance
                    </small>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className={`row ${Style.hero_2}`}>
          <div className="col-12 col-sm-6">
            <div className={Style.left_side}>
              <h1>Request a demo of Ratency</h1>
              <p className={Style.list_title}>Each search includes:</p>
              <ul>
                <li>
                  <img src={CheckIcon} alt="check icon" />
                  <span>
                    Access to our database of rates and availability collected
                    from 2017
                  </span>
                </li>
                <li>
                  <img src={CheckIcon} alt="check icon" />
                  <span>Location and radius settings</span>
                </li>
                <li>
                  <img src={CheckIcon} alt="check icon" />
                  <span>Specific vehicle types</span>
                </li>
                <li>
                  <img src={CheckIcon} alt="check icon" />
                  <span>Downloadable reports</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            {/* Intro pricing */}
            <div className={Style.intro_pricing}>
              <div className="row">
                <div className="col-12">
                  <span>Contact us for a free demo</span>
                  <h3>See a demo of what Ratency can do!</h3>
                  <a href="/contact">Contact us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`row ${Style.hero_3}`}>
          <div className="col-12 col-sm-6 nopadding">
            <div className={Style.left}>
              <div className={Style.about_card}>
                <h1>About us</h1>
                <p>
                  Ratency is an innovative database product backed by the
                  leading car rental aggregator in the Southern Hemisphere,
                  VroomVroomVroom.
                </p>
                <p>
                  Processing billions of units of data each year, Ratency
                  aggregates, filters, normalises and stores historical pricing
                  data for future use.
                </p>
                <p>
                  We serve insurers and specialists serving the credit hire
                  claims industry as well as providing aggregate data for trend
                  identification and actuary analysis.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 nopadding">
            <div className={Style.right}>
              <div className={Style.contact}>
                <h1>Contact us</h1>
                <p>
                  If you have any questions or require custom reporting
                  solutions, we would love to hear from you.
                </p>
                <div className={Style.list}>
                  <span
                    className={`material-icons-outlined md-blue-circle md-40-circle ${Style.icon}`}
                  >
                    email
                  </span>
                  <strong>
                    <a href="mailto:enquiries@ratency.com">
                      enquiries@ratency.com
                    </a>
                  </strong>
                </div>
                <div className={Style.list}>
                  <span
                    className={`material-icons-outlined md-blue-circle md-40-circle ${Style.icon}`}
                  >
                    room
                  </span>
                  Level 2, 349 Coronation Drive, Milton, QLD 4064 Australia
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RatencyFooter />
    </div>
  );
}
